<template>
  <div
    class="create-post bg-darkbg2 over-scroll"
    :class="hasDetails ? 'create-post--details' : ''"
  >
    <div class="d-flex align-center justify-space-between px-4 py-5">
      <span class="font-20 text-grey3 font-600">
        {{ hasDetails ? "Open Trade" : "Create a Post" }}</span
      >
      <img
        class="z-9 cursor-pointer"
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
      />
    </div>
    <v-divider color="cardbg"></v-divider>

    <!-- write post -->
    <div class="d-flex align-center justify-space-between pa-4">
      <div class="d-flex align-center w-100">
        <jazz-icon :diameter="40"></jazz-icon>
        <base-textarea
          class="mx-4 w-100"
          bgColor
          rows="1"
          placeholder="Write Something here ..."
          :defaultSelected="obj.text"
          @changeSelected="obj.text = $event"
        ></base-textarea>
      </div>

      <!-- file -->
      <v-file-input
        accept="image/.png , image/.jpg"
        @input="fileHandler"
        v-model="file"
        class="file-icon"
        hide-details
        :key="file"
        multiple
        v-if="!hasDetails"
      ></v-file-input>
    </div>

    <!-- chosed images -->
    <div
      class="d-flex align-center flex-wrap px-4 py-2"
      v-if="images.length > 0 && !hasDetails"
    >
      <div
        class="post-image relative radius-10 d-flex"
        v-for="(image, i) in images"
        :key="i"
      >
        <img
          width="14"
          class="z-9 cursor-pointer close-icon"
          :src="require('@/assets/images/icons/close.svg')"
          @click="removeImageHandler"
        />
        <v-img
          class="object-cover"
          width="120"
          height="120"
          :src="image"
          :lazy-src="image"
          aspect-ratio="1"
          cover
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey-lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <img alt="" />
      </div>
    </div>

    <!-- publish ? -->
    <div class="d-flex px-5 align-center justify-space-between w-100">
      <span class="font-18 text-greyBtnText">Publish for Everyone</span>
      <v-switch
        v-model="obj.withSubscription"
        hide-details
        inset
        class="d-inline-flex justify-end"
        color="#414152"
      ></v-switch>
    </div>

    <!-- open trade -->
    <!-- v-if="userDetails.role == 'trader'" -->
    <v-expansion-panels class="primary-transparent">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title @click="detailsToggle" class="px-4 py-3">
          <template v-slot:default>
            <div class="d-flex align-center">
              <div class="d-flex align-center">
                <img
                  width="40"
                  :src="require('@/assets/images/icons/card-icon.svg')"
                />
                <span class="text-primary font-16 mx-3">Open a Trade</span>
              </div>
            </div>
          </template>
        </v-expansion-panel-title>

        <v-expansion-panel-text class="bg-darkbg2">
          <div class="d-flex align-center mt-3 mb-1 px-5">
            <span class="text-greyBtnText font-20 font-600 ls-04">{{
              stepTitle(step)
            }}</span>
            <span class="bg-cardbg text-iconColor radius-30 px-3 py-1 mx-3"
              >{{ step }} / 3</span
            >
          </div>
          <!-- v-if="hasDetails" -->
          <div>
            <!-- step 1 -->
            <step-choose-coin
              v-if="step == 1"
              @nextHandler="nextHandler"
              @cancel="$emit('close')"
              :coins="coins"
              :loading="loadingCoins"
              :selectedCoinObj="selectedCoin"
            ></step-choose-coin>

            <!-- step 2 -->
            <step-set-trade
              :selectedCoin="selectedCoin"
              v-if="step == 2"
              @nextHandler="nextHandler"
              @back="step = 1"
              :objStep="obj"
            ></step-set-trade>

            <!-- step 3 -->
            <step-set-goals
              v-if="step == 3"
              @submitTradeHandler="submitTradeHandler"
              @back="step = 2"
              :loading="loading"
              :objStep="obj"
            ></step-set-goals>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- buttons -->
    <step-buttons
      :class="hasDetails ? 'create-post--hide' : ''"
      btnTitle="Share Post"
      cancelTitle="Cancel"
      @clickHandler="submitHandler"
      @cancel="$emit('close')"
      :loading="loading"
    ></step-buttons>
  </div>
</template>

<script>
import StepChooseCoin from "./steps/ChooseCoin.vue";
import StepSetTrade from "./steps/SetTrade.vue";
import StepSetGoals from "./steps/SetGoals.vue";
import StepButtons from "./steps/ButtonsSection.vue";
import {
  // AddAttachmentToPost,
  // AddPost,
  FetchCoinsPairs,
  AddTrade,
} from "@/services/Trade";
import { watchEffect } from "vue";
import { ref } from "vue";
import { mapGetters, useStore } from "vuex";

export default {
  components: {
    StepChooseCoin,
    StepSetTrade,
    StepSetGoals,
    StepButtons,
  },
  setup() {
    const store = useStore();
    let loadingCoins = ref(true);
    let coins = ref([]);
    let coinsExchange = ref([]);

    const fetchCoinsHandler = async () => {
      let res = await FetchCoinsPairs();
      watchEffect(() => {
        if (res.value) {
          loadingCoins.value = false;
          coins.value = res.value.getCoinsPairs.map((item) => {
            return { ...item, selected: false };
          });
          coinsExchange.value = res.value.getCoinsPairs;
        }
      });
    };

    return {
      store,
      loadingCoins,
      coins,
      coinsExchange,
      fetchCoinsHandler,
    };
  },
  data: () => ({
    loading: false,
    hasDetails: false,
    step: 1,
    selectedCoin: {},
    obj: {
      orderType: "limit",
      timeTag: "long",
      coins: [{}],
      withSubscription: false,
      takeProfit: [{}],
    },
    images: [],
    file: null,
  }),
  computed: {
    ...mapGetters(["userDetails"]),
  },
  methods: {
    detailsToggle() {
      this.hasDetails = !this.hasDetails;
    },
    stepTitle(step) {
      if (step == 1) return "Choose Coin";
      if (step == 2) return "Set Trade";
      if (step == 3) return "Set Goals";
    },
    async nextHandler(val) {
      if (!this.obj.text) {
        this.store.dispatch("showSnack", {
          text: "Text field must not be empty",
          color: "warning",
        });
        return;
      }
      if (this.step == 1) {
        this.selectedCoin = val;
        this.obj.coins[0] = {
          symbol: val.symbol,
        };
      }
      if (this.step == 2) this.obj = { ...this.obj, ...val };
      this.step = this.step + 1;
    },
    removeImageHandler() {
      this.images = [];
    },
    fileHandler(e) {
      if (e.target.files[0].size > 2049892) {
        this.store.dispatch("showSnack", {
          text: "image size must not be more than 2 MB!",
          color: "error",
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        this.images[0] = { src: event.target.result, file: e.target.files[0] };
      };
      reader.readAsDataURL(e.target.files[0]);
    },
    async submitHandler() {
      if (!this.obj.text) {
        this.store.dispatch("showSnack", {
          text: "Text field must not be empty",
          color: "warning",
        });
        return;
      }
      console.log("this.obj", this.images);
      // let res = await AddPost(this.obj);
      // if (res) {
      //   if (this.images[0]) {
      //     await AddAttachmentToPost({
      //       id: res.post._id,
      //       attachments: this.images,
      //     });
      //   }

      //   this.store.dispatch("showSnack", {
      //     text: res.message,
      //     color: "success",
      //   });
      //   this.$emit("close");
    },
    async submitTradeHandler(val) {
      const totalAmount = val.takeProfit.reduce(
        (total, item) => total + item.amount,
        0
      );


      if (totalAmount > 100) {
        this.store.dispatch("showSnack", {
          text: "Total amount of take profit should be less than 100",
          color: "error",
        });
        return;
      }
      
      this.obj = { ...this.obj, ...val };
      this.obj.takeProfit = this.obj.takeProfit.map((item) => {
        return {
          amount: item.amount?.toString(),
          price: item.price?.toString(),
          percent: item.percent?.toString(),
        };
      });

      this.obj.entries = this.obj.entries.map((item) => {
        return {
          value: item.value?.toString(),
        };
      });

      this.loading = true;
      let res = await AddTrade(this.obj);
      this.loading = false;

      if (res) {
        this.store.dispatch("showSnack", {
          text: res.message,
          color: "success",
        });
        // this.store.dispatch("updateTrades");
        this.$emit("close");
      }
    },
  },
  async created() {
    this.fetchCoinsHandler();
  },
};
</script>

<style lang="scss">
.create-post {
  position: relative;
  transition: 0.3s;

  &--hide {
    opacity: 0;
    visibility: hidden;
    height: 0px;
    padding: 0px !important;
    margin: 0px !important;
  }
  .v-expansion-panel-title__icon {
    i {
      color: #495bff !important;
    }
  }
  .v-expansion-panel-title__overlay {
    opacity: 0 !important;
  }
  .v-expansion-panel-text__wrapper {
    padding: 0px;
  }
}
</style>
